import { graphql, PageProps } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Box from "../components/Box/Box";
import GatsbyImageWithArtDirection from "../components/GatsbyImageWithArtDirection";
import GridSection from "../components/GridSection";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import { Paragraph, Paragraph2, Title } from "../components/Styled/Text";
import Wrapper from "../components/Wrapper";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { ContactsQuery } from "../types/pages/contacts";

interface Props extends PageProps {
  data: ContactsQuery;
}

const Contacts = ({ data, location }: Props) => {
  const { height } = useWindowSize();
  return (
    <>
      <Seo
        title={data.translationsJson.pages.contacts.seo.title}
        description={data.translationsJson.pages.contacts.seo.description}
        keywords={data.translationsJson.pages.contacts.seo.keywords}
        pathname={location.pathname}
        metaImage={
          data.translationsJson.pages.contacts.seo.image.childImageSharp
            .gatsbyImageData
        }
      />
      <Layout pathname={location.pathname}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            minHeight: height,
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              zIndex: 10,
              color: "#ffff",
              userSelect: "none",
              fontFamily: "custom_42263",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                fontFamily: "custom_42266",
                fontSize: "21px",
                fontWeight: 400,
                color: get("palette.text.dark_gold"),
                marginBottom: "30px",
                visibility:"hidden",
              }}
              as="span"
            >
              {data.translationsJson.pages.contacts.header.subtitle}
            </Box>
            {data.translationsJson.pages.contacts.header.title.map((t, i) => (
              <Title variant="bigHero" key={i} sx={{ color: "white" }}>
                {t}
              </Title>
            ))}

            <Paragraph2
              variant="hero"
              sx={{
                color: "#fff",
                opacity: 0.85,
                textAlign: ["center", "center", "center", "center"],
                maxWidth: [
                  "90%",
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                  clampBuilder(599, 1680, 19.625, 44.625),
                ],
              }}
            >
              {data.translationsJson.pages.contacts.header.description}
            </Paragraph2>
          </Box>

          <GatsbyImageWithArtDirection
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
            imgStyle={{ objectFit: "cover" }}
            desktopImage={data.headerDesktop.childImageSharp.gatsbyImageData}
            mobileImage={data.headerMobile.childImageSharp.gatsbyImageData}
            alt=""
          />
        </Box>
        <Wrapper
          sx={{
            marginBottom: "40px",
          }}
        >
          <GridSection
            firstChildrenType="imageBox"
            secondChildrenType="textBox"
            mainContainerSx={{
              marginBottom: "40px",
            }}
          >
            <>
              <Box
                as={GatsbyImage}
                alt={data.translationsJson.pages.contacts.section.imageAlt}
                image={
                  data.translationsJson.pages.contacts.section.image
                    .childImageSharp.gatsbyImageData
                }
              />
            </>
            <>
              <Box
                sx={{
                  marginBottom: "28px",
                }}
              >
                {data.translationsJson.pages.contacts.section.title.map(
                  (t, i) => (
                    <Title
                      sx={{
                        textAlign: ["center", "center", "center", "center"],
                      }}
                      key={i}
                      variant="paragraph"
                    >
                      {t}
                    </Title>
                  )
                )}
              </Box>
              <Paragraph
                as="a"
                sx={{
                  textAlign: "center",
                  color: get("palette.text.brown"),
                  ":hover": {
                    color: [
                      "inherit",
                      "inherit",
                      "inherit",
                      get("palette.text.dark_gold"),
                    ],
                  },
                  alignSelf: "center",
                  width: "100%",
                }}
                href={`${data.site.siteMetadata.addressLink}`}
              >
                <div
                  contentEditable="false"
                  dangerouslySetInnerHTML={{
                    __html: data.site.siteMetadata.address,
                  }}
                ></div>
              </Paragraph>
              <Paragraph
                sx={{
                  textAlign: "center",

                  alignSelf: "center",
                  width: "100%",
                }}
              >
                <span>
                  {data.translationsJson.pages.contacts.section.fields[0].text}
                </span>

                <Box
                  sx={{
                    display: "inline",
                    cursor: "pointer",
                    color: get("palette.text.dark_gold"),
                    ":hover": {
                      opacity: "0.85",
                    },
                  }}
                  as="a"
                  href={`mailto:${data.site.siteMetadata.email}`}
                >
                  {data.site.siteMetadata.email}
                </Box>
              </Paragraph>
              <Paragraph
                sx={{
                  textAlign: "center",
                  alignSelf: "center",
                  width: "100%",
                }}
              >
                <span>
                  {data.translationsJson.pages.contacts.section.fields[1].text}
                </span>

                <Box
                  sx={{
                    display: "inline",
                    color: get("palette.text.dark_gold"),
                  }}
                  as="a"
                  href={`tel:${data.site.siteMetadata.phone}`}
                >
                  {data.site.siteMetadata.phone}
                </Box>
              </Paragraph>
            </>
          </GridSection>
          <GridSection
            firstChildrenType="textBox"
            secondChildrenType="imageBox"
          >
            <>
              <Box
                sx={{
                  marginBottom: "28px",
                }}
              >
                {data.translationsJson.pages.contacts.shop.items[2].title.map(
                  (t, i) => (
                    <Title
                      key={i}
                      sx={{
                        alignSelf: "center",

                        textAlign: ["center", "center", "center", "center"],
                        width: "100%",
                      }}
                      variant="paragraph"
                    >
                      {t}
                    </Title>
                  )
                )}
              </Box>
              {data.translationsJson.pages.contacts.shop.items[2].description.map(
                (d, i) => (
                  <Paragraph
                    key={i}
                    sx={{
                      textAlign: ["center", "center", "center", "center"],

                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    <span
                      contentEditable="false"
                      dangerouslySetInnerHTML={{ __html: d }}
                    ></span>
                  </Paragraph>
                )
              )}
              <Paragraph
                sx={{
                  textAlign: ["center", "center", "center", "center"],
                }}
              >
                <Box
                  sx={{
                    display: "inline",
                    cursor: "pointer",
                    textAlign: ["center", "center", "center", "center"],
                    color: get("palette.text.dark_gold"),
                  }}
                  as="a"
                  href={`mailto:${data.translationsJson.pages.contacts.shop.items[2].url}`}
                >
                  {data.translationsJson.pages.contacts.shop.items[2].urltext}
                </Box>
              </Paragraph>
            </>
            <>
              <Box
                as={GatsbyImage}
                sx={{
                  width: "100%",
                }}
                alt={
                  data.translationsJson.pages.contacts.shop.items[2].imageAlt
                }
                image={
                  data.translationsJson.pages.contacts.shop.items[2].image
                    .childImageSharp.gatsbyImageData
                }
              />
            </>
          </GridSection>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              minHeight: ["60vh", height, height, height],
              textAlign: "center",
              padding: "0 7.5%",
            }}
          >
            <Box
              sx={{
                marginBottom: "28px",
              }}
            >
              {data.translationsJson.pages.contacts.shop.header.title.map(
                (t, i) => (
                  <Title key={i} variant="section">
                    {t}
                  </Title>
                )
              )}
            </Box>
            <Paragraph
              sx={{
                width: [
                  "100%",
                  clampBuilder(599, 1680, 14.875, 41),
                  clampBuilder(599, 1680, 14.875, 41),
                  clampBuilder(599, 1680, 14.875, 41),
                ],
              }}
            >
              <span
                contentEditable="false"
                dangerouslySetInnerHTML={{
                  __html:
                    data.translationsJson.pages.contacts.shop.header
                      .description,
                }}
              ></span>
            </Paragraph>
          </Box>

          <GridSection
            firstChildrenType="imageBox"
            secondChildrenType="textBox"
          >
            <>
              <Box
                as={GatsbyImage}
                sx={{
                  width: "100%",
                }}
                alt={
                  data.translationsJson.pages.contacts.shop.items[1].imageAlt
                }
                image={
                  data.translationsJson.pages.contacts.shop.items[1].image
                    .childImageSharp.gatsbyImageData
                }
              />
            </>
            <>
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                {data.translationsJson.pages.contacts.shop.items[1].title.map(
                  (t, i) => (
                    <Title
                      key={i}
                      sx={{
                        alignSelf: "center",

                        textAlign: ["center", "center", "center", "center"],
                        width: "100%",
                      }}
                      variant="paragraph"
                    >
                      {t}
                    </Title>
                  )
                )}
              </Box>

              {data.translationsJson.pages.contacts.shop.items[3].description.map(
                (d, i) => (
                  <Paragraph
                    key={i}
                    sx={{
                      textAlign: ["center", "center", "center", "center"],

                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    {d}
                  </Paragraph>
                )
              )}
              <Paragraph
                sx={{
                  textAlign: ["center", "center", "center", "center"],
                  marginBottom: "40px",
                }}
              >
                <Box
                  sx={{
                    display: "inline",
                    cursor: "pointer",
                    color: get("palette.text.dark_gold"),
                  }}
                  as="a"
                  href={`tel:${data.translationsJson.pages.contacts.shop.items[3].url}`}
                >
                  {data.translationsJson.pages.contacts.shop.items[3].urltext}
                </Box>
              </Paragraph>
              <Box
                sx={{
                  marginBottom: "24px",
                }}
              >
                {data.translationsJson.pages.contacts.shop.items[0].title.map(
                  (t, i) => (
                    <Title
                      key={i}
                      sx={{
                        alignSelf: "center",

                        textAlign: ["center", "center", "center", "center"],
                        width: "100%",
                      }}
                      variant="paragraph"
                    >
                      {t}
                    </Title>
                  )
                )}
              </Box>
              {data.translationsJson.pages.contacts.shop.items[0].description.map(
                (d, i) => (
                  <Paragraph
                    key={i}
                    sx={{
                      textAlign: ["center", "center", "center", "center"],

                      alignSelf: "center",
                      width: "100%",
                    }}
                  >
                    {d}
                  </Paragraph>
                )
              )}
              <Paragraph
                sx={{
                  textAlign: ["center", "center", "center", "center"],
                }}
              >
                <Box
                  sx={{
                    display: "inline",
                    cursor: "pointer",
                    color: get("palette.text.dark_gold"),
                  }}
                  as="a"
                  href={`tel:${data.translationsJson.pages.contacts.shop.items[0].url}`}
                >
                  {data.translationsJson.pages.contacts.shop.items[0].urltext}
                </Box>
              </Paragraph>
            </>
          </GridSection>
        </Wrapper>
      </Layout>
    </>
  );
};

export default React.memo(Contacts);

export const contactsQuery = graphql`
  query ContactsQuery {
    headerDesktop: file(
      relativeDirectory: { eq: "pages/contacts/header" }
      name: { eq: "desktop" }
    ) {
      id
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100)
      }
    }
    headerMobile: file(
      relativeDirectory: { eq: "pages/contacts/header" }
      name: { eq: "mobile" }
    ) {
      id
      relativeDirectory
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    translationsJson {
      pages {
        contacts {
          seo {
            title
            description
            keywords
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          header {
            subtitle
            title
            description
          }
          section {
            title
            fields {
              text
            }
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            imageAlt
          }
          shop {
            header {
              description
              title
            }
            items {
              description
              image {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              urltext
              url
              imageAlt
              title
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        address
        addressLink
        email
        phone
      }
    }
  }
`;
